import './App.css';
import Navbar from './components/navbar';
import Footer from './components/footer';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

function App() {


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_gx71vm1', 'template_t6nh8bo', form.current, 'HXu-_V8WlY7lWHzGy')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      form.current.reset();
      e.preventDefault();

    };

  return (
    <div className="App">

      <Navbar />

      <div className="home">

        <div className="text-home">
          <span id="text-home1">
          Built by Devs, for Devs
          </span>

          <span id="text-home2">
          We connect freelance devs
          </span>

          <span id="text-home3">
          with high-ticket clients
          </span>
        </div>

        <div>

          <a href="#section"><button>
            Apply
          </button></a>
          
        </div>

        <div className="arrow">
          <span></span>
          <span></span>
          <span></span>
        </div>

      </div>

      <div className="home2">
        <div className="quienes-somos">
          <h2 className='text-principal-home2'>About us</h2>
          <span>At <b>DSG Solutions</b>, we are the genuine link between <b>developers</b> and <b>high-ticket clients</b>. <br />
           Our story dates back to our beginnings as developers in 2020, and since then, <br /> we have been striving to demonstrate how we can <b> provide real assistance</b>  to businesses in <b>prestigious niches</b></span>
        </div>

        <div className="servicios">
          <h2 className="title-servicios">
            Services
          </h2>

          <div className="servicios-container">
            <div className="row">
              <div className="service">
                <h2>Strategic Connection</h2>
                <i class="fa fa-link "></i>
                <p>We facilitate strategic connections between <br /> developers and high-ticket clients <br /> who share interests and objectives.</p>
               </div>


               <div className="service">
                <h2>High-value Proposal Creation</h2>
                <i class="fa fa-line-chart"></i>
                <p>We specialize in design <br /> and presenting real high-value offers<br /> demonstrating their market need</p>
              </div>

              <div className="service">
                <h2>Customized Sales Strategies</h2>
                <i class="fa fa-lightbulb-o"></i>
                <p>We tailor personalized sales strategies to meet <br /> our clients' unique needs and maximize <br /> their business opportunities</p>
              </div>

              <div className="service">
                <h2>High-Ticket Prospect Generation</h2>
                <i class="fa fa-filter"></i>
                <p>We create a list of high-quality prospects <br /> that align with our clients' <br /> criteria, offering valuable opportunities.</p>
              </div>

              <div className="service">
                <h2>Relationship Tracking</h2>
                <i class="fa fa-users"></i>
                <p>We don't just close deals; <br /> we also maintain and nurture long-term relationships <br /> with our clients and developers.</p>
              </div>

             


            </div>
          </div>
        </div>

        <span className='title-contact'>Are you a developer looking for high-ticket clients? <br /> Join now</span>

        <div className="form-contact">

<div id="section"></div>
        

          <div className="box">
        
          <form ref={form} onSubmit={sendEmail}>
  <input type="text" name='user_name' className="form-control" placeholder='Name' required />
  <input type="email" name='message' className="form-control" placeholder='Email' required />
  <input type="tel" name='message' className="form-control" placeholder='Message' required />
  <button type="submit">Submit request</button>
</form>
          </div>


        </div>

        

      </div>

      <Footer/>



    </div>
  );
}

export default App;
