import React from 'react'
import "./footer.css"

function footer() {
  return (
    <div className='footer-container'>
      <div className="social-icons">
        <a href="https://www.instagram.com/dsg.solutions/"><i className="fa fa-instagram"></i></a>
        <a href="https://www.linkedin.com/in/marcelo-resola/"><i className="fa fa-linkedin"></i></a>
        <a href="https://www.dsgsolution.com"><i className="fa fa-globe"></i></a>
      </div>


<div className="footerbottom">
    <p>Copyrigth &copy;2023; Designed by <span>DSG SOLUTIONS</span></p>
</div>

    </div>
  )
}

export default footer